<template>
  <v-app>
    <div class="header">
      <v-btn class="language-button" outlined @click="changeLanguage">
        <img :src="languageIcon" alt="Language Icon" class="icon">
      </v-btn>
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <landing-left-component :language="language" />
        </v-col>
        <v-col cols="12" md="6">
          <landing-right-component :language="language" @change-language="changeLanguage" />
        </v-col>
      </v-row>
      <div class="legal-document-links">
        <p v-if="language === 'es'">
          <a href="/terminos-de-uso">Términos de uso</a> - <a href="/politica-de-privacidad" >Política de privacidad</a>
        </p>
        <p v-if="language === 'en'">
          <a href="/terms-of-use">Terms of use</a> - <a href="/privacy-policy" >Privacy policy</a>
        </p>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import LandingLeftComponent from './LandingLeftComponent';
import LandingRightComponent from './LandingRightComponent';
import esIcon from '@/assets/icon/es.png';
import enIcon from '@/assets/icon/en.png';

export default {
  name: 'LandingComponent',
  components: {
    LandingLeftComponent,
    LandingRightComponent
  },
  data() {
    return {
      language: 'es'
    };
  },
  computed: {
    languageIcon() {
      return this.language === 'es' ? enIcon : esIcon;
    }
  },
  methods: {
    changeLanguage() {
      this.language = this.language === 'es' ? 'en' : 'es';
    },
    routeTo(routeName) {
      const routeData = this.$router.resolve({ name: routeName });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  padding: 10px 0;
  z-index: 1000;
}
.language-button {
  background-color: #80b537;
  border: 3px solid #80b537;
  border-radius: 10px;
  padding: 10px !important;
}
.icon {
  width: 30px;
  height: 30px;
}
.v-container {
  margin-top: 2%;
}
.legal-document-links {
  margin: 0 auto !important;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  text-align: center;
  color: #A8A8A8;
  p {
    a {
      color: #A8A8A8;
      text-decoration: none;
      &:hover {
        color: #80b537;
        text-decoration: underline;
      }
    }
  }
}
</style>
