<template>
  <div class="logo-container">
    <img src="@/assets/logo.png" alt="Logo" class="logo">
    <p class="title">{{ getTitle('Entra en el', 'Enter the') }}</p>
    <p class="title">{{ getTitle('Universo', 'Universe') }}</p>
    <p class="title">RobynGoods</p>
    <div>
      <p class="download-text">{{ getTitle('Descarga la App', 'Download the App') }}</p>
    </div>
    <div class="buttons-container">
      <v-btn class="download-button" outlined @click="openUrl('https://apps.apple.com/us/app/robyngoods/id6497792240')">
        <img src="@/assets/apple-icon.png" alt="Apple Store" class="icon">
        <span class="button-text-span">
          {{ getTitle('Descarga en', 'Download on') }}<br>
          <strong class="button-text-strong">{{ getTitle('App Store', 'App Store') }}</strong>
        </span>
      </v-btn>
      <v-btn class="download-button" outlined @click="openUrl('https://play.google.com/store/apps/details?id=es.robyngoods.app')">
        <img src="@/assets/play-icon.png" alt="Google Play" class="icon">
        <span class="button-text-span">
          {{ getTitle('Descarga en', 'Download on') }}<br>
          <strong class="button-text-strong">{{ getTitle('Google Play', 'Google Play') }}</strong>
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingLeftComponent',
  props: {
    language: {
      type: String,
      required: true
    }
  },
  methods: {
    getTitle(esText, enText) {
      return this.language === 'es' ? esText : enText;
    },
    openUrl(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped>
.logo-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  margin-left: 15%;
}
.logo {
  width: 400px;
  margin-left: 8%;
  margin-bottom: -60px;
  margin-top: -30px;
}
.buttons-container {
  display: flex;
  justify-content: flex-start; 
  gap: 20px; 
  text-align: left;
  margin-left: 15%;
}
.download-button {
  padding: 25px !important;
  background-color: #80b537;
  border: 3px solid #80b537;
  border-radius: 10px;
}
.icon {
  width: 45px;
  height: 45px;
}
.v-application .title {
  font-family: 'Inter', sans-serif !important;
  font-size: 80px !important;
  padding-bottom: 3%;
  font-weight: bold;
  color: #80b537;
  margin-left: 15%;
}
.download-text {
  font-family: 'Inter', sans-serif !important;
  font-size: 26px;
  font-weight: bold;
  text-align: left;
  margin-left: 15%;
}
.button-text-span {
  font-family: 'Inter', sans-serif !important;
  font-size: 12px !important;
  color: #fff;
  padding-left: 10%;
  text-transform: none; 
  text-align: left;
}
.button-text-strong {
  font-family: 'Inter', sans-serif !important;
  font-weight: bold !important;
  color: #fff;
  font-size: 16px !important;
}

  /* Estilos para pantallas menores o iguales a 960px */
  @media (max-width: 959px) {
  .logo-container {
    align-items: center;
    margin-left: 0;
    margin-top: 7%;
  }
  .logo {
    width: 400px;
    margin-left: 0;
    text-align: center;
    margin-bottom: -12% !important;
    margin-top: -12% !important;
  }
  .title, .download-text, .buttons-container {
    margin-left: 0;
    text-align: center;
  }
  .logo {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .v-application .title {
    font-size: 50px !important;
    margin: 0 auto;
  }
  .buttons-container {
    flex-direction: column;
    gap: 10px;
  }
}
  /* Estilos específicos para pantallas de 1024px de ancho IPAD MINI HORIZONTAL*/
  @media (min-width: 1024px) and (max-width: 1024px) {
  .v-application .title {
    font-size: 50px !important;
    padding-bottom: 1%;
  }
} 
</style>
